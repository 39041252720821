<template>
  <r-block
    width="320px"
    no-padding
  >
    <r-title>Выберите тип акта</r-title>
    <el-select
      v-model="type"
      class="r-select"
      filterable
      placeholder="Выберите тип"
    >
      <el-option
        v-for="item in types"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      />
    </el-select>
    <r-block
      start
      no-padding
    >
      <r-button
        type="primary"
        :disabled="!type"
        :loading="loading"
        @click="create"
      >
        Подтвердить
      </r-button>
    </r-block>
  </r-block>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      source_id: this.$store.state.bdd.actsUrl,
      type: null
    }
  },
  computed: {
    types() {
      return this.$store.state.bdd.actRelated?.act_type?.data || []
    }
  },
  created() {
    this.type = this.types?.[0]?.id || null
  },
  methods: {
    async create() {
      this.loading = true

      try {
        const data = {
          act_type_id: this.type
        }
        const { data: response } = await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data
        })

        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateActs',
          value: true
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'act',
          value: response?.[0]?.id
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
